import React from 'react';
import juan from '../../images/juan.jpg'

const Instructor = () => {
    return ( 
        <div className="contenido-instructor contenedor">
            <h2>Conoce a tu Instructor</h2>

            <div className="info-instructor">
                <div className="imagen">
                    <img src={juan} alt="instructor" />
                </div>
                <div className="texto">
                    <h3>Juan Pablo De la torre Valdez</h3>
                    <p className="titulo">He sido Desarrollador Web por 14 años.</p>
                   
                    <p>Comencé en programación web con Sitios hechos en Flash y ActionScript, así como HTML y CSS; de ahi comencé a conocer JavaScript que se ha convertido en mi lenguaje favorito.</p>

                    <p>Después comence a aprender a crear sitios y aplicaciones web con PHP, WordPress, Django y Ruby On Rails, para hacerlos más dínamicos.</p>

                    <p>En años recientes mi enfoque ha sido Node y PHP (Laravel) para el backend; y React y Vue para el front end (Si puedes esperar un curso de Vue pronto). Aunque también me gusta enseñar otros temas como CSSGrid y Flexbox, Freelancing, Bootstrap y WordPress.</p>
                </div>
            </div>
        </div>
    );
}
 
export default Instructor;

